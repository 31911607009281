import React, {Component} from 'react';
import {dev, devIp, ip} from "../package.json"

class Bruynzeel extends Component {

    state = {
        zipCode: 1,
        kg: 1,
        ldm: 0,
        price: 0,
        SEK: undefined, // 1 euro in sek, remember to update this with oil surcharge
        oilSurcharge: undefined,
        time: false,
        ip: dev ? devIp : ip
    };

    componentDidMount() {
        fetch(this.state.ip + "settings")
            .then(res => res.json().then(result => {
                this.setState({SEK: result.currencies[0].value, oilSurcharge: result.oilSurcharge}, this.calcPrice)
            }));
    }


    handleChange = e => {
        e.target.value = e.target.value.replace(" ", "")
        if (e.target.name === "ldm" && e.target.value > 13.4) {
            // IGNORED
        } else if (e.target.name === "kg" && e.target.value > 24790) {
            // IGNORED
        } else if (e.target.name === "zipCode" && e.target.value > 98999) {
            // IGNORED
        } else {
            this.setState({[e.target.name]: e.target.value.replace(",", ".")}, this.calcPrice)
        }
    };


    lookupPrice = () => {
        let zipArray =
            [0, 19999, 21999, 22999, 23999, 24999, 25999, 26999, 27999, 28999, 29999, 30999, 31999, 33999, 34999, 35999, 36999, 37999,
                38999, 39999, 40999, 41999, 42999, 43999, 44999, 45999, 46999, 47999, 50999, 51999, 52999, 53999, 54999, 55999, 56999,
                57999, 58999, 59999, 60999, 61999, 63999, 64999, 65999, 66999, 67999, 68999, 69999, 70999, 71999, 72999, 73999, 74999,
                75999, 76999, 77999, 78999, 79999, 80999, 81999, 82999, 83999, 84999, 85999, 86999, 87999, 88999, 89999, 90999, 91999,
                92999, 93999, 94999, 95999, 96999, 97999, 98999];

        // zipArray.forEach(zip=>{
        //     fb.firestore().collection("bruynzeel").doc("zipCodes").set({zip})
        // });
        // console.log(zipArray);
        // fb.firestore().collection("bruynzeel").doc("zipCodes").set({zipArray});

        let kgArray = [0, 21, 36, 51, 76, 101, 500, 1000, 2500, 5000, 10000, 15000, 20000, 25000];
        // fb.firestore().collection("bruynzeel").doc("2018").set({kgArray});
        let prices = [
            [233.35, 322.13, 322.13, 391.88, 462.90, 241.82, 153.68, 119.78, 84.75, 59.89, 45.20, 45.20, 43.94, 42.69,],
            [233.35, 322.13, 322.13, 391.88, 462.90, 81.36, 46.33, 41.81, 25.99, 15.82, 11.30, 11.30, 11.30, 11.30,],
            [233.35, 322.13, 322.13, 391.88, 462.90, 81.36, 46.33, 41.81, 25.99, 15.82, 11.30, 11.30, 11.30, 11.30,],
            [233.35, 322.13, 322.13, 391.88, 462.90, 81.36, 46.33, 41.81, 25.99, 15.82, 11.30, 11.30, 11.30, 11.30,],
            [253.65, 322.13, 322.13, 391.88, 462.90, 101.70, 64.41, 54.24, 30.51, 19.21, 12.56, 12.56, 12.56, 12.56,],
            [253.65, 253.65, 322.13, 391.88, 462.90, 115.26, 71.19, 62.15, 32.77, 20.34, 13.81, 13.81, 13.81, 13.81,],
            [253.65, 253.65, 322.13, 391.88, 462.90, 115.26, 71.19, 62.15, 32.77, 20.34, 13.81, 13.81, 13.81, 13.81,],
            [253.65, 253.65, 322.13, 391.88, 462.90, 118.65, 77.97, 63.28, 33.90, 21.47, 15.07, 15.07, 15.07, 15.07,],
            [253.65, 253.65, 322.13, 391.88, 462.90, 132.21, 79.10, 65.54, 35.03, 23.73, 16.32, 16.32, 16.32, 15.07,],
            [253.65, 253.65, 322.13, 391.88, 462.90, 132.21, 79.10, 65.54, 35.03, 23.73, 16.32, 16.32, 16.32, 15.07,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 140.12, 84.75, 68.93, 36.16, 23.73, 16.32, 16.32, 16.32, 15.07,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 160.46, 90.40, 75.71, 42.94, 24.86, 20.09, 20.09, 20.09, 18.83,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 178.54, 100.57, 84.75, 47.46, 27.12, 21.34, 21.34, 21.34, 20.09,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 166.11, 93.79, 77.97, 45.20, 24.86, 20.09, 20.09, 20.09, 18.83,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 166.11, 93.79, 77.97, 45.20, 24.86, 20.09, 20.09, 20.09, 18.83,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 179.67, 102.83, 85.88, 50.85, 29.38, 22.60, 22.60, 22.60, 21.34,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 168.37, 97.18, 79.10, 46.33, 25.99, 21.34, 21.34, 21.34, 20.09,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 197.75, 114.13, 90.40, 57.63, 36.16, 27.62, 27.62, 27.62, 26.37,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 196.62, 110.74, 89.27, 48.59, 33.90, 26.37, 26.37, 26.37, 25.11,],
            [233.35, 322.13, 322.13, 391.88, 462.90, 196.62, 110.74, 89.27, 55.37, 33.90, 26.37, 26.37, 26.37, 25.11,],
            [233.35, 322.13, 322.13, 391.88, 462.90, 196.62, 110.74, 89.27, 55.37, 33.90, 26.37, 26.37, 26.37, 25.11,],
            [233.35, 322.13, 322.13, 391.88, 462.90, 196.62, 110.74, 89.27, 55.37, 33.90, 26.37, 26.37, 26.37, 25.11,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 179.67, 102.83, 85.88, 50.85, 29.38, 22.60, 22.60, 22.60, 21.34,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 196.62, 110.74, 90.40, 55.37, 33.90, 26.37, 26.37, 26.37, 25.11,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 204.53, 119.78, 97.18, 59.89, 40.68, 30.13, 30.13, 30.13, 28.88,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 204.53, 119.78, 97.18, 59.89, 40.68, 30.13, 30.13, 30.13, 28.88,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 197.75, 114.13, 90.40, 57.63, 36.16, 27.62, 27.62, 27.62, 26.37,],
            [253.65, 253.65, 322.13, 391.88, 462.90, 196.62, 110.74, 89.27, 55.37, 33.90, 26.37, 26.37, 26.37, 25.11,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 196.62, 110.74, 89.27, 55.37, 33.90, 26.37, 26.37, 26.37, 25.11,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 196.62, 110.74, 90.40, 55.37, 33.90, 26.37, 26.37, 26.37, 25.11,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 210.18, 122.04, 100.57, 63.28, 41.81, 30.13, 30.13, 30.13, 28.88,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 210.18, 122.04, 100.57, 63.28, 41.81, 30.13, 30.13, 30.13, 28.88,],
            [253.65, 253.65, 322.13, 391.88, 462.90, 186.45, 108.48, 88.14, 54.24, 32.77, 23.86, 23.86, 23.86, 21.34,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 186.45, 108.48, 88.14, 54.24, 32.77, 23.86, 23.86, 23.86, 21.34,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 196.62, 110.74, 89.27, 55.37, 33.90, 26.37, 26.37, 26.37, 25.11,],
            [253.65, 253.65, 322.13, 391.88, 462.90, 211.31, 124.30, 101.70, 64.41, 42.94, 32.64, 32.64, 32.64, 30.13,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 211.31, 124.30, 101.70, 64.41, 42.94, 32.64, 32.64, 32.64, 30.13,],
            [253.65, 253.65, 322.13, 391.88, 462.90, 213.57, 131.08, 103.96, 66.67, 46.33, 33.90, 33.90, 33.90, 32.64,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 221.48, 136.73, 109.61, 73.45, 48.59, 36.41, 36.41, 36.41, 35.16,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 237.30, 146.90, 117.52, 79.10, 55.37, 43.94, 43.94, 42.69, 41.43,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 229.39, 141.25, 111.87, 74.58, 54.24, 38.92, 38.92, 38.92, 36.41,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 229.39, 141.25, 111.87, 74.58, 54.24, 38.92, 38.92, 38.92, 36.41,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 221.48, 136.73, 109.61, 73.45, 48.59, 36.41, 36.41, 36.41, 35.16,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 237.30, 146.90, 117.52, 79.10, 55.37, 43.94, 43.94, 42.69, 41.43,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 237.30, 146.90, 117.52, 79.10, 55.37, 43.94, 43.94, 42.69, 41.43,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 213.57, 131.08, 103.96, 66.67, 46.33, 33.90, 33.90, 33.90, 32.64,],
            [253.65, 253.65, 322.13, 391.88, 462.90, 221.48, 136.73, 109.61, 73.45, 48.59, 36.41, 36.41, 36.41, 35.16,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 229.39, 141.25, 111.87, 74.58, 54.24, 38.92, 38.92, 38.92, 36.41,],
            [380.47, 380.47, 380.47, 391.88, 462.90, 237.30, 146.90, 117.52, 79.10, 55.37, 43.94, 43.94, 42.69, 41.43,],
            [508.49, 508.49, 508.49, 508.49, 508.49, 241.82, 153.68, 119.78, 84.75, 59.89, 45.20, 45.20, 43.94, 42.69,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 279.99, 173.27, 136.85, 96.68, 70.31, 46.46, 46.46, 46.46, 43.94,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 268.69, 170.75, 133.09, 94.17, 66.54, 45.20, 45.20, 43.94, 42.69,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 279.99, 173.27, 136.85, 96.68, 70.31, 46.46, 46.46, 46.46, 43.94,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 268.69, 170.75, 133.09, 94.17, 66.54, 45.20, 45.20, 43.94, 42.69,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 268.69, 170.75, 133.09, 94.17, 66.54, 45.20, 45.20, 43.94, 42.69,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 279.99, 173.27, 136.85, 96.68, 70.31, 46.46, 46.46, 46.46, 43.94,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 283.75, 178.29, 145.64, 102.95, 72.82, 50.22, 50.22, 48.97, 47.71,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 283.75, 178.29, 145.64, 102.95, 72.82, 50.22, 50.22, 48.97, 47.71,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 357.83, 229.77, 180.80, 135.60, 96.68, 64.03, 64.03, 64.03, 61.52,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 385.45, 256.13, 217.21, 170.75, 120.53, 81.61, 80.36, 81.61, 77.84,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 374.15, 244.83, 199.63, 151.92, 110.49, 72.82, 72.82, 72.82, 69.06,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 364.11, 237.30, 195.87, 146.90, 104.21, 70.31, 70.31, 70.31, 65.29,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 374.15, 244.83, 199.63, 151.92, 110.49, 72.82, 72.82, 72.82, 69.06,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 374.15, 244.83, 199.63, 151.92, 110.49, 72.82, 72.82, 72.82, 69.06,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 377.92, 246.09, 200.89, 160.71, 114.25, 74.08, 74.08, 74.08, 74.08,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 377.92, 246.09, 200.89, 160.71, 114.25, 74.08, 74.08, 74.08, 74.08,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 385.45, 256.13, 217.21, 170.75, 120.53, 81.61, 81.61, 81.61, 77.84,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 399.26, 268.69, 229.77, 187.08, 130.58, 86.63, 86.63, 86.63, 85.38,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 399.26, 268.69, 229.77, 187.08, 130.58, 86.63, 86.63, 86.63, 85.38,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 418.10, 283.75, 247.34, 203.40, 144.39, 90.40, 90.40, 90.40, 90.40,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 418.10, 283.75, 247.34, 203.40, 144.39, 90.40, 90.40, 90.40, 90.40,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 430.65, 297.56, 261.15, 212.19, 153.18, 97.93, 97.93, 97.93, 95.42,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 440.70, 307.61, 271.20, 220.98, 163.22, 101.70, 101.70, 101.70, 101.70,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 423.12, 288.78, 256.13, 210.93, 148.15, 95.42, 95.42, 95.42, 92.91,],
            [508.38, 508.38, 508.38, 508.38, 508.38, 458.28, 323.93, 285.01, 233.53, 180.80, 111.74, 111.74, 111.74, 110.49,],
        ];
        let max = 0;
        for (let i = 0; i < zipArray.length; i++) {
            if (zipArray[i] < this.state.zipCode) {
                max = i;
            }
        }

        let kgMax = 0;
        let ldm = this.state.ldm * 1850;
        let ldmOrKg = this.state.kg > ldm ? this.state.kg : ldm;
        for (let i = 0; i < kgArray.length; i++) {
            if (kgArray[i] <= ldmOrKg) {
                kgMax = i;
            }
        }
        let multiply = kgMax >= 5;
        let extra = this.calcNlToSE(ldmOrKg);
        // console.log(prices[max][kgMax])
        let handling = this.handling(ldmOrKg);
        return {price: prices[max][kgMax], multiply, ldmOrKg, extra, handling};
    };

    calcNlToSE = kg => {
        let kgArr = [0, 3885, 7585, 13135, 18685,];
        let price = [144, 136, 127, 118, 108];
        const min = 174;
        let resultValue;
        // FULL LOAD
        if (kg === 24790) {
            resultValue = 1570;
        } else {
            for (let i = 0; i < kgArr.length; i++) {
                if (kgArr[i] < kg) {
                    resultValue = (price[i] * (kg / 1850))
                }
            }

        }
        resultValue < min ? resultValue = min * this.state.SEK * (1 + this.state.oilSurcharge / 100) : resultValue *= this.state.SEK * (1 + this.state.oilSurcharge / 100);
        return resultValue;
    };

    handling = kg => {
        let ldm = kg / 1850;
        let pll = Math.ceil(ldm / 0.4) ;
        pll = Math.ceil(pll * 2.41);
        return pll * this.state.SEK * 2;
    };

    maut = kg => {
        let price = 0.54;
        let max = 11800;
        let resultValue = 0;

        if (kg >= 100) {
            resultValue = Math.ceil((kg / 100)) * price;
        } else {
            resultValue = price;
        }

        return resultValue < max ? resultValue * this.state.SEK : max * this.state.SEK;
    };

    calcPrice() {
        let price = this.lookupPrice();
        let checked = this.state.time ? 700 : 0;
        let calcPrice = 0;
        // Between 100 and 200
        if (price.multiply && price.ldmOrKg <= 199) {
            calcPrice = (checked + ((price.price * 2))) //* (1 + this.state.oilSurcharge / 100))// + checked + price.extra;
            // Higher than 200
        } else if (price.multiply) {
            calcPrice = ((((price.ldmOrKg / 100) * price.price)))//* (1 + this.state.oilSurcharge / 100))// + checked + price.extra;
            // Lower than 100
        } else {
            calcPrice = price.price// + price.extra + checked;
        }
        let split = 1.5 * this.state.SEK;
        let mautValue = this.maut(price.ldmOrKg);

        calcPrice += price.extra + checked + price.handling + split + mautValue;


        console.log(price);
        console.log(mautValue);

        this.setState({price: calcPrice})
    }

    handleCheck = () => {
        this.setState({time: !this.state.time}, this.calcPrice);
    };

    render() {
        return (
            <div style={{padding: 10, margin: 10}}>
                <div className={"columns"}>
                    <div className={"card column is-4"}>
                        <InputP type="text" text={"Postnummer:"} value={this.state.zipCode} onChange={this.handleChange} name={"zipCode"}/>
                        <InputP type="number" text={"LDM:"} value={this.state.ldm} onChange={this.handleChange} name={"ldm"}/>
                        <InputP type="number" text={"Kg:"} value={this.state.kg} onChange={this.handleChange} name={"kg"}/>
                        <InputP type="checkbox" text={"Tidslossing:"} value={this.state.timeLoad} onChange={this.handleCheck}/>
                        <h1>{"Price: " + Math.round(this.state.price * 100) / 100 + "SKR*"}</h1>
                    </div>
                </div>
            </div>
        );
    }
}

const InputP = props => {
    return (
        <div className="field">
            <label className="label">{props.text}</label>
            <div className="control">
                <input className={props.type === "checkbox" ? null : "input"} type={props.type} value={props.value}
                       onChange={props.onChange} name={props.name}/>
            </div>
        </div>
    )
};

export default Bruynzeel;
